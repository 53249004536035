import { FC, useState } from 'react'
import { Minus, Plus } from '@components/icons'
import clsx from 'clsx'
import styles from './ExpandableRibbon.module.css'

interface Props {
  title: string
  content?: any
}

const ExpandableRibbon: FC<Props> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false)
  const Icon = isOpen ? Minus : Plus
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  const [pStyleBold, pStyleRegular] = [
    'body-2-bold text-text-subdued',
    'text-text-subdued body-2',
  ]
  const defaultContent = (
    <div className={styles.content}>
      <div className="py-0.5">
        <p className={pStyleBold}>QLD, NT, WA & TAS:</p>
        <p className={pStyleRegular}>Order by Monday 16th December</p>
      </div>
      <div className="py-0.5">
        <p className={pStyleBold}>VIC, SA & NSW:</p>
        <p className={pStyleRegular}>Order by Wednesday 18th December</p>
      </div>
      <div className="py-0.5">
        <p className={pStyleBold}>LARGE GIFTWARE:</p>
        <p className={pStyleRegular}>Order by Monday 16th December</p>
      </div>
      <div className="py-0.5">
        <p className={pStyleBold}>CLICK & COLLECT (VIC only):</p>
        <p className={pStyleRegular}>Order by Thursday 19th December</p>
      </div>
      <p className={clsx(pStyleRegular, 'py-0.5')}>
        After this date please email <b>hello@hoskings.com.au</b> to confirm
        delivery estimate
      </p>
      <p className={clsx(pStyleRegular, 'mb-4')}>
        Please note items are shipped from Victoria
      </p>
    </div>
  )

  return (
    <div className={clsx(styles.root, 'bg-brand-polar-white')}>
      <div
        className="flex justify-center items-center gap-2 py-1.5 cursor-pointer"
        onClick={toggleOpen}
      >
        <p className={pStyleBold}>{title}</p>
        <Icon width={11} height={11} />
      </div>
      {isOpen && (content || defaultContent)}
    </div>
  )
}
export default ExpandableRibbon
